<template>
  <b-col class="page-container p-3">
    <b-col class="content-wrap p-3">
      <TopNavBar
      v-bind:secondTierLoadingDone="true"/>

      <b-container class="p-3">
        <b-row align-h="center"
               >
          <b-col cols="content" class="pt-5 p-0 col-12 col-md-12 col-lg-10 col-xl-8">
            <b-card class="p-1 mt-5"
          style="min-width: 300px"
            >
              <b-card-header class="bg-primary text-white w-100">
                <b-row>
                <b-col id="title"
                  class="col-8 text-white bg-transparent list-group-item"
                  style="border: none !important">
                  {{$t('Term Add')}}
                </b-col>
                  <b-col id="info-right"
                  class="col-2 p-1 text-center">
                  <span
                    :active="false"
                    :disabled="true"
                    class="list-group-item bg-transparent"
                    style="border: none !important"
                    @click="toggleInfo()"
                  >
                    <i class="fas fa-info-circle custom-button text-white"></i>
                  </span>
                </b-col>
                <b-col id="settings-right"
                  class="col-2 p-1 text-center">
                  <span
                    :active="false"
                    :disabled="true"
                    class="list-group-item bg-transparent"
                    style="border: none !important"
                    @click="toggleOptions()"
                  >
                    <i class="fas fa-sliders-h custom-button text-white"></i>
                  </span>
                </b-col>
                  </b-row>
              </b-card-header>
              <div class="pt-3" v-if="info">
                <b-alert show dismissible variant="info">
                  {{info}}
                </b-alert>
              </div>
              <div v-if="info2">
                <b-alert show dismissible variant="warning">
                  {{info2}}
                </b-alert>
              </div>
              <div v-if="info3">
                <b-alert show dismissible variant="info">
                  {{info3}}
                </b-alert>
              </div>
              <div v-if="info4">
                <b-alert show dismissible variant="warning">
                  {{info4}}
                </b-alert>
              </div>
              <div v-if="info5">
                <b-alert show dismissible variant="info">
                  {{info5}}
                </b-alert>
              </div>
              <div v-if="info6">
                <b-alert show dismissible variant="success">
                  {{info6}}
                </b-alert>
              </div>
              <b-form @submit.prevent="submit">
                <div>
                  <b-card>
                    <b-card  class="p-0" v-if="showSettings">
                      <b-card-header class="bg-danger text-white">
                        {{$t('Select type')}}
                      </b-card-header>
                      <div class="form-check pt-3">
                        <input v-model="type" v-bind:value="typeOptions[0]" class="form-check-input" type="radio"
                               checked="checked" name="inlineRadioOptions" id="inlineRadio1">
                        <label class="form-check-label" for="inlineRadio1">{{$t('Genderneutral')}}</label>
                      </div>
                      <div class="form-check">
                        <input v-model="type" v-bind:value="typeOptions[1]" class="form-check-input" type="radio"
                               name="inlineRadioOptions" id="inlineRadio2">
                        <label class="form-check-label" for="inlineRadio2">{{$t('Genderstar')}}</label>
                      </div>
                      <div class="form-check">
                        <input v-model="type" v-bind:value="typeOptions[2]" class="form-check-input" type="radio"
                               name="inlineRadioOptions" id="inlineRadio3">
                        <label class="form-check-label" for="inlineRadio3">{{$t('Pairform')}}</label>
                      </div>
                    </b-card>
                    <b-nav tabs class="pt-3" v-if="showSettings">
                        <b-nav-item
                          :active="!tabSwitch"
                          :disabled="!tabSwitch"
                          @click="changeTab(0)"
                        >
                          {{$t('Persons')}}
                        </b-nav-item>
                        <b-nav-item
                          :active="tabSwitch"
                          :disabled="tabSwitch"
                          @click="changeTab(1)"
                        >
                          {{$t('Special case')}}
                        </b-nav-item>
                    </b-nav>
                      <b-card-body>
                        <div v-if="!tabSwitch">
                          <input :v-model="activateTabName='persons'" type="hidden">

                          <!--singular masculine-->
                          <b-form-group
                            id="singular_masculine_group"
                            label-for="singular_masculine"
                            :label="$t('Singular masculine')"
                          >
                            <b-form-input
                              id="singular_masculine"
                              type="text"
                              :placeholder="$t('Enter singular masculine')"
                              v-model="singular_masculine"
                            ></b-form-input>
                          </b-form-group>

                          <!--singular feminine-->
                          <b-form-group
                            id="singular_feminine_group"
                            label-for="singular_feminine"
                            :label="$t('singular feminine')"
                          >
                            <b-form-input
                              id="singular_feminine"
                              type="text"
                              :placeholder="$t('Enter singular feminine')"
                              v-model="singular_feminine"
                            ></b-form-input>
                          </b-form-group>

                          <!--plural masculine-->
                          <b-form-group
                            id="plural_masculine_group"
                            label-for="plural_masculine"
                            :label="$t('plural masculine')"
                          >
                            <b-form-input
                              id="plural_masculine"
                              type="text"
                              :placeholder="$t('Enter plural masculine')"
                              v-model="plural_masculine"
                            ></b-form-input>
                          </b-form-group>

                          <!--plural feminine-->
                          <b-form-group
                            id="plural_feminine_group"
                            label-for="plural_feminine"
                            :label="$t('plural feminine')"
                          >
                            <b-form-input
                              id="plural_feminine"
                              type="text"
                              :placeholder="$t('Enter plural feminine')"
                              v-model="plural_feminine"
                            ></b-form-input>
                          </b-form-group>

                          <!--singular gender neutral-->
                          <b-form-group
                            id="singular_gender_neutral_group"
                            label-for="singular_gender_neutral"
                            :label="$t('singular gender neutral')"
                          >
                            <b-form-input
                              class="form-control is-valid"
                              id="singular_gender_neutral"
                              type="text"
                              :placeholder="$t(singularPlaceholder())"
                              v-model="singular_gender_neutral"
                            ></b-form-input>
                          </b-form-group>

                          <!--singular gender neutral-->
                          <b-form-group
                            id="plural_gender_neutral_group"
                            label-for="plural_gender_neutral"
                            :label="$t('plural gender neutral')"
                          >
                            <b-form-input
                              class="form-control is-valid"
                              id="plural_gender_neutral"
                              type="text"
                              :placeholder="$t(pluralPlaceholder())"
                              v-model="plural_gender_neutral"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div v-if="tabSwitch">
                          <input :v-model="activateTabName='special_case'" type="hidden">
                          <!--Term-->
                          <b-form-group
                            id="term_group"
                            label-for="term"
                            :label="$t('term')"
                          >
                            <b-form-input
                              id="term"
                              type="text"
                              :placeholder="$t('Enter term')"
                              v-model="term"
                            ></b-form-input>
                          </b-form-group>

                          <div class="col-md-12 no-gutters" v-if="$v.$error">
                            <small class="form-text text-danger" v-if="!$v.alternative.specialCaseIsValidated">Fee
                              should be decimal
                              format.</small>
                          </div>
                          <!--Alternative-->
                          <b-form-group
                            id="alternative_group"
                            label-for="alternative"
                            :label="$t('alternative')"
                          >
                            <b-form-input
                              class="form-control is-valid"
                              id="alternative"
                              type="text"
                              :placeholder="$t(alternativePlaceholder())"
                              v-model="alternative"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </b-card-body>
                    <hr>
                    <!--Example-->
                    <b-form-group
                      class="example_group"
                      id="example"
                      label-for="example"
                      :label="$t('example')"
                    >
                      <b-form-textarea
                        id="example"
                        max-rows="6"
                        rows="3"
                        :placeholder="$t('Enter example')"
                        v-model="example"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-card>
                </div>

                <div v-if="error">
                  <b-alert show variant="danger">
                    {{error}}
                  </b-alert>
                </div>

                <div v-if="success">
                  <b-alert show variant="success">
                    {{success}}
                  </b-alert>
                </div>

                <vue-recaptcha
                  @expired="onCaptchaExpired"
                  @verify="onCaptchaVerified"
                  ref="recaptcha"
                  :sitekey="this.recaptchaSiteKey"
                  size="invisible"
                ></vue-recaptcha>
                <div class="mt-3">
                  <b-button class="mr-3" type="submit" variant="primary">{{$t("Add")}}</b-button>
                </div>
              </b-form>
            </b-card>
          </b-col>
        </b-row>
      </b-container>

      <div>
        <b-modal hide-footer ref="registerModal" :title="$t('you are not logged in')">
          <div class="d-block text-center">
            <h3>{{$t('wanna create an account?')}}</h3>
          </div>
          <router-link :to="{name: 'auth.login'}" class="mt-2 btn btn-outline-info btn-block">{{$t("Go to login")}}</router-link>
          <router-link :to="{name: 'auth.register'}" class="mt-2 btn btn-outline-success btn-block">{{$t("Go to register")}}
          </router-link>
          <b-button block @click="hideRegisterModal" class="mt-3 btn btn-outline-danger">{{$t("Cancel")}}</b-button>
        </b-modal>
      </div>
      <div>
        <b-modal hide-footer ref="tabToggleModal" :title="$t('Persons/ special case')">
          <div class="d-block text-center">
            <h3>{{$t("Do you want to change the type of this entry?")}}</h3>
            <small>{{$t("Only data in the active tab will be saved")}}</small>
          </div>
          <b-button @click="activeTab" block class="mt-2 btn btn-outline-info btn-block">{{$t("Yes")}}</b-button>
          <b-button @click="hideTabToggleModal" block class="mt-2 btn btn-outline-danger btn-block">{{$t("Cancel")}}
          </b-button>
        </b-modal>
      </div>
    </b-col>
    <GenderAppFooter/>
  </b-col>
</template>

<script>
import { validationMixin } from 'vuelidate'
import TopNavBar from '../../../components/TopNavBar'
import { DictionaryEntryService } from '../../../services/dictionaryEntryService'
import { dictionaryEntryMixin } from '../../../mixins/dictionaryEntryMixin'
import { listMixin } from '../../../mixins/listMixin'
import GenderAppFooter from '../../../components/Footer'
import VueRecaptcha from 'vue-recaptcha'
import { API_URL, RECAPTCHA_SITE_KEY } from '../../../env'
import axios from 'axios'

const specialCaseIsValidated = (alternative, vm) => {
  if (alternative) {
    if (vm.term === '') {
      vm.error = vm.$t('if you enter term, please also provide alternative')
      return false
    } else {
      return true
    }
  }
  // if alternative is empty, we are good.
  return true
}
const singularIsValidated = (singularGenderNeutral, vm) => {
  if (singularGenderNeutral) {
    if (vm.singular_masculine === '' && vm.singular_masculine === '') {
      vm.error = vm.$t('if you enter singular gender neutral, you have to provide singular feminine and/or singular masculine.')
      return false
    } else {
      return true
    }
  }
  // if singularGenderNeutral is empty, we are good.
  return true
}

const pluralIsValidated = (pluralGenderNeutral, vm) => {
  if (pluralGenderNeutral) {
    if (vm.plural_masculine === '' && vm.plural_feminine === '') {
      vm.error = vm.$t('if you enter plural gender neutral, you have to provide plural feminine and/or plural masculine.')
      return false
    } else {
      return true
    }
  }
  // if pluralGenderNeutral is empty, we are good.
  return true
}
const dictionaryEntryIsValid = (example, vm) => {
  // we need some data in the form
  const allIsEmpty = vm.singular_masculine === '' && vm.singular_feminine === '' && vm.plural_masculine === '' && vm.plural_feminine === '' && vm.term === ''
  if (allIsEmpty) {
    vm.error = vm.$t('please enter some gender specific terms')
    return false
  }
  return true
}

export default {
  name: 'DictionaryEntryAdd',
  // insert the following code for vue-meta to work
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          vmid: 'description',
          content: this.description
        },
        {
          property: 'og:title',
          vmid: 'og:title',
          content: this.title
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content: this.description
        },
        {
          property: 'og:url',
          vmid: 'og:url',
          content: this.canonicalUrl
        },
        {
          name: 'twitter:title',
          vmid: 'twitter:title',
          content: this.title
        },
        {
          name: 'twitter:description',
          vmid: 'twitter:description',
          content: this.description
        }
      ],
      link: [
        { rel: 'canonical', href: this.canonicalUrl }
      ]
    }
  },
  data () {
    return {
      info: '',
      info2: '',
      info3: '',
      info4: '',
      info5: '',
      info6: '',
      term: '',
      type: '',
      typeOptions: ['neut', 'star', 'pair'],
      alternativePlaceholders: ['Enter alternative neutral', 'Enter alternative star', 'Enter alternative pair'],
      singularPlaceholders: ['Enter singular neutral', 'Enter singular star', 'Enter singular pair'],
      pluralPlaceholders: ['Enter plural neutral', 'Enter plural star', 'Enter plural pair'],
      alternative: '',
      example: '',
      singular_masculine: '',
      singular_feminine: '',
      plural_masculine: '',
      plural_feminine: '',
      singular_gender_neutral: '',
      plural_gender_neutral: '',
      showSettings: false,
      tabSwitch: false,
      activateTabName: '',
      error: '',
      success: '',
      recaptchaSiteKey: RECAPTCHA_SITE_KEY,
      title: 'Neuer Eintrag · gender app',
      description: 'Erfasse einen neuen Eintrag und erweitere das demokratische Wörterbuch',
      canonicalUrl: 'https://genderapp.org/dictionary_entry/add'

    }
  },
  mixins: [validationMixin, dictionaryEntryMixin, listMixin],
  validations: {
    alternative: {
      specialCaseIsValidated: specialCaseIsValidated
    },
    singular_gender_neutral: {
      singularIsValidated: singularIsValidated
    },
    plural_gender_neutral: {
      pluralIsValidated: pluralIsValidated
    },
    example: {
      dictionaryEntryIsValid: dictionaryEntryIsValid
    }
  },
  methods: {
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset()
    },
    submit: function () {
      this.$refs.recaptcha.execute()
    },
    onCaptchaVerified (recaptchaToken) {
      const token = recaptchaToken
      this.$refs.recaptcha.reset()
      const instance = this
      const activetab = this.activateTabName
      this.$v.$touch()
      if (this.$v.$invalid) {
        if (!this.error) {
          this.error = this.$t('Something went wrong 😵')
        }
      } else {
        let data = {}
        if (activetab === 'persons') {
          data = {
            type: this.type,
            singular_masculine: this.singular_masculine.trim(),
            singular_feminine: this.singular_feminine.trim(),
            plural_masculine: this.plural_masculine.trim(),
            plural_feminine: this.plural_feminine.trim(),
            singular_gender_neutral: this.singular_gender_neutral.trim(),
            plural_gender_neutral: this.plural_gender_neutral.trim(),
            example: this.example.trim()
          }
        } else {
          data = {
            type: this.type,
            term: this.term.trim(),
            alternative: this.alternative.trim(),
            example: this.example.trim()
          }
        }
        axios.post(`${API_URL}/auth/recapcha/`, { token: token })
          .then(response => {
            // console.log(response.data.toString())
            if (response.data.status) {
              DictionaryEntryService.create(data)
                .then(response => {
                  instance.error = ''
                  instance.success = ''
                  // the own item will be liked automatically
                  if (instance.type === 'neut') instance.like(response.data.id)
                })
                .then(response => {
                  // we set the search term to the entered dictionary entry
                  const genderSpecificFields = [
                    instance.term,
                    instance.singular_feminine,
                    instance.singular_masculine,
                    instance.plural_feminine,
                    instance.plural_masculine]
                  const genderSpecificFieldsClean = genderSpecificFields.filter(n => n)
                  instance.setUrl(genderSpecificFieldsClean[0] + '&sec' || '')
                })
                .catch((error) => {
                  // this.error = error.status === 404 ? 'Something went wrong' : error.message
                  instance.error = error.message.error
                  instance.success = ''
                })
            }
          })
      }
    },
    showRegisterModal () {
      this.$refs.registerModal.show()
    },
    hideRegisterModal () {
      this.$refs.registerModal.hide()
    },
    showToggleModal () {
      this.$refs.tabToggleModal.show()
    },
    hideTabToggleModal () {
      this.$refs.tabToggleModal.hide()
    },
    alternativePlaceholder () {
      if (this.type === 'neut') {
        return this.alternativePlaceholders[0]
      } else if (this.type === 'star') {
        return this.alternativePlaceholders[1]
      } else if (this.type === 'pair') {
        return this.alternativePlaceholders[2]
      }
    },
    singularPlaceholder () {
      if (this.type === 'neut') {
        return this.singularPlaceholders[0]
      } else if (this.type === 'star') {
        return this.singularPlaceholders[1]
      } else if (this.type === 'pair') {
        return this.singularPlaceholders[2]
      }
    },
    pluralPlaceholder () {
      if (this.type === 'neut') {
        return this.pluralPlaceholders[0]
      } else if (this.type === 'star') {
        return this.pluralPlaceholders[1]
      } else if (this.type === 'pair') {
        return this.pluralPlaceholders[2]
      }
    },
    changeTab (tIndex) {
      this.showToggleModal()
    },
    activeTab () {
      this.tabSwitch = !this.tabSwitch
      this.hideTabToggleModal()
    },
    async getObjectData () {
      const params = new URLSearchParams(window.location.search)
      const uid = parseInt(params.get('uid'))

      // if this is not copy, prevent from calling term_copy api
      if (!uid) return false

      await DictionaryEntryService.getDictionaryEntryById(uid)
        .then((response) => {
          const dataSet = response.data
          this.term = dataSet.term
          this.singular_masculine = dataSet.singular_masculine
          this.singular_feminine = dataSet.singular_feminine
          this.plural_masculine = dataSet.plural_masculine
          this.plural_feminine = dataSet.plural_feminine
          // Hint: We only want to fill the gender specific values
          this.tabSwitch = !!this.term
          // Hint: If we copy the entries of a 'special case' we want to  switch the tab
        })
        .catch((err) => {
          this.error = err.message.detail
        })
    }
  },
  async mounted () {
    await this.getObjectData()
    this.type = this.type || this.typeOptions[0]
  },
  components: { GenderAppFooter, TopNavBar, VueRecaptcha }
}
</script>

<style scoped>

  .example {
    max-height: 280px;
    -ms-overflow-style: none;
  }

  .example::-webkit-scrollbar {
    display: none;
  }
</style>
